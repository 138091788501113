import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { ISidebar, SidebarService } from '../sidebar/sidebar.service';
import { GlobalService } from '../../services/global.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RequestServices } from '../../services/request.services';
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  @ViewChild('addWholesaler') addWholesalerModal: TemplateRef<any>
  @ViewChild('instructions') instructionsModal: TemplateRef<any>

  wholesalerModalRef: BsModalRef;
  addWholesalerForm: FormGroup;
  instructionsModalRef: BsModalRef;
  displayName = localStorage.getItem('UserName');
  sidebar: ISidebar;
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  subscription: Subscription;
  fileUpdModalRef: BsModalRef;
  fileToUpload: any;
  modalRef: BsModalRef;
  checkroute = false;
  checkdashboard = false;
  wholesalerName = "";
  cTime: any = 'April 18, 2018 9:45am';
  public modalConfig: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
  };
  proObj = undefined;
  licenseFile = undefined;
  fileMsg: string = '';
  isLicenseValid: boolean = true;
  fileText: string = "Select License File";
  _top_nav_bar = {
    licenseValidityStr: '',
    _btn_disable: false,
  }
  topNavVar = {
    wholesalers: [],
    wholesalerId: undefined
  }
  InventoryForm: FormGroup;

  EntityType : any;
  constructor(
    public global: GlobalService,
    private sidebarService: SidebarService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private service: RequestServices,
    public router: Router,
  ) {
    this.isDarkModeActive = this.getColor().indexOf('dark') > -1 ? true : false;
  }

  ngOnInit(): void {
    this.EntityType = JSON.parse(localStorage.getItem('EntityType'));
    this.getWholesaler();
    this.initWholesalerForm();
    this.getinventory();
    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );

    this.proObj = this.global.getProObj();
    console.log(this.proObj, 'pro')
    this.wholesalerName = this.proObj.wholesalerName;
  }
  ngAfterViewInit() {
    this.checkWholesaler();
  }
  submitInventory(obj: any){
    let etrapp ={
      proId: this.global.getProObj().id,
      categoryId: 1,
      quantity: obj.Etrapps ,
      createBy: this.global.getProObj().id.toString(),
      editBy: this.global.getProObj().id.toString()
    }
    let catrdige= {
      proId: this.global.getProObj().id,
      categoryId: 2,
      quantity: obj.Catridges ,
      createBy: this.global.getProObj().id.toString(),
      editBy: this.global.getProObj().id.toString()
    }
    const array=[{...etrapp}, {...catrdige}]
    this.service
      .postRequest(`ProInventory/AddorUpdateInventory`, array).subscribe(
        (response) => {
          if (response.statusCode == "200") {
            this.global.showNotification('Success', '', 'Inventory updated successfully');
            this.getinventory()

          } 
        },
        (error) => {
          this.global.showNotification('Error', '', 'Error updating inventory');
          this.global._show_loader = false;
          this.getinventory()
        }
      );


  }
  initInventoryForm() {
    this.InventoryForm = this.formBuilder.group({
      Etrapps: [0, Validators.compose([Validators.required])],
      Catridges: [0, Validators.compose([Validators.required])],
    });
  }
  stopDropdownClick(event: MouseEvent) {
    event.stopPropagation();
  }

  getinventory(){
    this.initInventoryForm()
    this.service
      .getRequest(`ProInventory/GetInventorybyProId?proId=${this.global.getProObj().id}`).subscribe(
        (response) => {
          if (response.statusCode == "200") {
            debugger
            const res = response.data
            const etrapps= res[0].quantity
            const catridges= res[1].quantity
            this.InventoryForm.controls['Etrapps'].setValue(etrapps)
            this.InventoryForm.controls['Catridges'].setValue(catridges)
          } 
        },
        (error) => {
          this.global.showNotification('Error', '', 'Records do not exist');
          this.global._show_loader = false;
          // this.initInventoryForm()
        }
      );
  }
  getWholesaler() {
    this.service
      .getRequest(`Wholesaler/GetAllWholesalers`).subscribe(
        (response) => {
          if (response.statusCode == "200") {
            this.topNavVar.wholesalers = response.data
          } else if (response.statusCode == "409") {

          } else {

          }
        },
        (error) => {
          this.global.showNotification('Error', '', 'Records do not exist');
          this.global._show_loader = false;
        }
      );
  }
  initWholesalerForm() {
    this.addWholesalerForm = this.formBuilder.group({
      id: [this.global.getProObj().id, Validators.compose([Validators.nullValidator])],
      wholesalerId: [0, Validators.compose([Validators.nullValidator])],
      name: [null, Validators.compose([Validators.required])],
    })
  }
  editWholesaler(name: any) {
    this.topNavVar.wholesalers.find((wholesaler: any) => {
      if (name == wholesaler.name) {
        this.addWholesalerForm.controls['wholesalerId'].setValue(wholesaler.id);
        this.addWholesalerForm.controls['name'].setValue(wholesaler.name);
      }
    })
    this.openFormModal(this.addWholesalerModal);
  }
  checkWholesaler() {
    this.topNavVar.wholesalerId = this.global.getProObj().wholesalerId;
    if (this.topNavVar.wholesalerId === null) {
      this.openFormModal(this.addWholesalerModal)
    }
    else {
      this.global.name = this.global.getProObj().wholesalerName
    }
    if (this.topNavVar.wholesalerId !== null && !this.global.getProObj().isGuideAck && (localStorage.getItem('AcknowledgeGuidelines') == 'false')) {
      this.openInstructionsModal(this.instructionsModal);
    }
  }
  submitAddWholesaler(obj: any) {
    this.saveWholesaler(obj);
  }
  saveWholesaler(obj) {
    let payload = this.global.getProObj();
    this.topNavVar.wholesalers.find((wholesaler: any) => {
      if (obj.name == wholesaler.name) {
        payload.wholesalerId = wholesaler.id
      }
    })
    this.global._show_loader = true;
    this.service.postRequest("Pro/UpdatePro", payload).subscribe(
      (response) => {
        if (response.statusCode == "410") {
          this.global._show_loader = false;
        } else if (response.statusCode == "201") {
          this.closeAddWholesalerModal();
          this.global.showNotification("Success", "", "Wholesaler saved successfully.");
          this.global.name = this.addWholesalerForm.value.name
          localStorage.setItem("WHOLESALER", this.global.name);
          let newObj = this.global.getProObj();
          newObj.wholesalerId = this.addWholesalerForm.value.wholesalerId
          newObj.wholesalerName = this.addWholesalerForm.value.name
          localStorage.setItem(this.global.settings.PRO_OBJ, btoa(JSON.stringify(newObj)));
          this.global._show_loader = false;
          if (!this.global.getProObj().isGuideAck && localStorage.getItem('AcknowledgeGuidelines') == 'false') {
            this.openInstructionsModal(this.instructionsModal)
          }
          localStorage.setItem("AcknowledgeGuidelines", 'true');
        } else {
          this.global.showNotification("Error", "", "Something went wrong.");
          this.global._show_loader = false;
        }
      },
      (error) => {
        this.global.showNotification("Error", "", "Something went wrong.");

        this.global._show_loader = false;
      }
    );
  }

  openFormModal(wholesalerModal: TemplateRef<any>) {
    this.global.modalConfig.class = "modal-md modal-dialog-centered";
    this.wholesalerModalRef = this.modalService.show(
      wholesalerModal,
      this.global.modalConfig
    );
  }
  closeAddWholesalerModal() {
    this.wholesalerModalRef.hide()
  }
  closeInstructionsModal() {
    this.instructionsModalRef.hide();
    localStorage.setItem("AcknowledgeGuidelines", 'true')
  }
  acknowledgeGuidelines() {
    this.global._show_loader = true;
    this.service.getRequest(`Pro/IsGuideAcknowledge?ProID=${this.global.getProObj().id}&IsGuideAck=${true}`).subscribe(
      (response) => {
        if (response.statusCode == "200") {
         this.global._show_loader = false;
          this.closeInstructionsModal();
        } else {
          this.global.showNotification("Error", "", "Something went wrong.");
          this.global._show_loader = false;
        }
      },
      (error) => {
        this.global.showNotification("Error", "", "Something went wrong.");

        this.global._show_loader = false;
      }
    );
  }
  fullScreenClick() {

    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  logoutuser() {
    var USERStr = localStorage.getItem(this.global.settings.PRO_OBJ)
    var obj = JSON.parse(atob(USERStr))
    var objid = this.proObj.loginId;

    this.service.postRequest(`UserLogin/Logout?loginID=1`, objid).subscribe(
      (response) => {
        // localStorage.removeItem('userCredentials');
        // localStorage.removeItem(this.global.settings.ETRAPP_USER);
        // localStorage.removeItem("UserName");
        // localStorage.removeItem("ID");
        // localStorage.removeItem("logo");
        // localStorage.removeItem("empID");
        // localStorage.removeItem("OrgID");
        // localStorage.removeItem("RoleID");
        // localStorage.removeItem("uType");
        // localStorage.removeItem("EntityType");
        localStorage.clear();
        this.router.navigate(['/login']);
      },
      (error) => {
        this.global.showNotification('Error', '', 'Something Went Wrong');
      }
    );


  }
  openOrgSettingModal(openOrgSettingTemp) {
    this.modalRef = this.modalService.show(openOrgSettingTemp, this.modalConfig);
  }
  closeOrgSettingModal() {
    this.modalRef.hide();
  }
  closeLicenseModal() {
    if (this.fileToUpload !== undefined) {
      this.fileToUpload = undefined;
      this.fileText = "Select License File";
    }
    this.modalRef.hide();
  }

  openUploadLicenseModal(template: TemplateRef<any>) {
    this.fileUpdModalRef = this.modalService.show(template, this.modalConfig);
  }
  mobileMenuButtonClick = (event: { stopPropagation: () => void; }, containerClassnames: string) => {
    if (event) { event.stopPropagation(); }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }
  routeToProfile() {
    this.router.navigate(['/profile']);
  }
  searchAreaClick(event) {
    event.stopPropagation();
  }
  changeText(event: any) {
    this.fileToUpload = event.target.files.item(0);
    this.fileText = (event.currentTarget.files[0].name.length > 22 ? event.currentTarget.files[0].name.substr(0, 20) + '...' : event.currentTarget.files[0].name);

  }
  openFileUploader() {
    this.fileMsg = '';
    document.getElementById('fileInput').click();
    var a = document.getElementById('fileInput');
  }
  menuButtonClick = (e: { stopPropagation: () => void; }, menuClickCount: number, containerClassnames: string) => {
    if (e) { e.stopPropagation(); }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  getColor() {
    return localStorage.getItem(environment.themeColorStorageKey)
      ? localStorage.getItem(environment.themeColorStorageKey)
      : environment.defaultColor;
  }
  onDarkModeChange(event) {
    let color = this.getColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    localStorage.setItem(environment.themeColorStorageKey, color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }
  openInstructionsModal(instructionsModal: TemplateRef<any>) {
    this.global.modalConfig.class = "modal-xl modal-dialog-centered";
    this.instructionsModalRef = this.modalService.show(
      instructionsModal,
      this.global.modalConfig
    );
  }
}

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="row">
  <div class="col-12 col-md-12">
    <h1>Dashboard</h1>
    <div class="top-right-button-container font-weight-bold mb-2">
      <span class="mr-5">Total Customers:<span class="ml-2 text-success">{{dashboardVar.totalCustomers}}</span></span>
      <span class="">Days Since New Customer:<span
          class="ml-2 text-success">{{dashboardVar.daySinceNewCustomer}}</span></span>
    </div>
    
    

  </div>
</div>
<hr class="" />
<br>
<div class="row">
  <div class="col-lg-6 col-md-12  col-sm-12 mt-2">
    <div class="card" #eTrappAlerts id="eTrappAlerts">
      <div class="card-body">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">eTrapp Alerts</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <!-- <button class="test" style="font-size: 10px; border: 0px; margin-right: 10px;">Add Service Ticket</button> -->
          <span class="mr-1 font-weight-bold ">Show</span>
          <div class="btn-group " dropdown>
            <button id="button-basic" dropdownToggle type="button"
              class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
              {{dashboardVar.eTrappSelectedVlaue.label}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let item of itemOptionsOrders; let i = index">
                <a *ngIf="i !== 0" (click)="onChangeOrderBy(item,1)" class="c-pointer"
                  [ngClass]="dashboardVar.eTrappSelectedVlaue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                  {{ item.label }}
                </a>
              </li>
            </ul>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block p-0 ml-3 mr-4 " type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(eTrappAlerts)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
          <div class="position-absolute card-top-buttons mt-4 mr-3 p-0 pt-1 ">
            <button class="btn btn-header-light icon-button pl-0" (click)="loadEtrappAlerts()">
              <i class="simple-icon-refresh p-0 m-0"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div [ngClass]="isFullScreen==true ? 'fullScreenClass' : 'dashboard-list-with-user' ">
          <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
            <table class="table">
              <thead class="lead-th">
                <tr>
                  <th [appSort]="dashboardVar.eTrappAlertsArr" data-order="desc" data-name="customer.name" scope="col"
                    class="c-pointer">
                    Customer
                    <i [ngClass]="arrowHead" aria-hidden="true" style="font-size: 10px !important;"></i>
                  </th>
                  <th scope="col" style="width: 19%;">eTrapp Device</th>
                  <th  scope="col " style="width: 18%;">Generated On</th>
                  <th scope="col" style="width: 18%;">Current Alert</th>
                  <th scope="col" style="width: 18%;">Action</th>
                </tr>
              </thead>
              <tbody *ngIf="dashboardVar.eTrappAlertsArr.length> 0; else nodata" style="font-size: 10px">
                <tr *ngFor="let etrapp of dashboardVar.eTrappAlertsArr ; let i=index">
                  <td >
                    <p class="c-pointer mb-0">
                      <span >{{etrapp.customer.name}} </span><i
                        class="fa fa-phone text-primary"></i><span class="text-primary">
                        {{etrapp.customer.phoneNumber}}</span>
                      <br />
                      <span class="text-muted text-small">
                        {{etrapp.customer.address}}, {{etrapp.customer.state}}, {{etrapp.customer.city}},
                        {{etrapp.customer.zip}}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p class="text-primary c-pointer" (click)="openConsumerModal(etrapp)">
                      {{ etrapp.deviceName}}
                    </p>
                    <br />
                    <!-- <span> Master Bed Attic </span> -->
                  </td>
                  <td>
                    <p style="font-size: 12px;">
                      {{this.global.utcDateToLocal(etrapp.generatedOn)}}
                    </p>
                  </td>
                  <td>
                    <img style="width: 20px; height: 20px" src="../../../../assets/images/alert_icon.png" alt="" />
                    <span> {{etrapp.definition}}</span>
                  </td>
                  <td class="mt-2" style="padding: 20px 0px;"    *ngIf="!etrapp.isAcknowledged" >  
                    <span (click)="openConsumerModal(etrapp)" class="test c-pointer"
                    > + Add Ticket</span>   
                  </td>
                  <td class="mt-2" *ngIf="etrapp.isAcknowledged" >
                      <span  style="color: #145185; font-size: 14px;" >Acknowleged</span>
                  </td>
                </tr>
              </tbody>
              <ng-template #nodata>
                <tbody>
                  <tr>
                    <td class="text-center" colspan="4">
                      <p class="card-title">
                        {{dashboardVar.loadingEtrappAlert}}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div class="col-lg-6 col-md-12  col-sm-12 mt-2 ">
    <div class="card" #cartAlerts id="cartAlerts">
      <div class="card-body">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">Cartridge Alerts</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <span class="mr-1 font-weight-bold ">Show</span>
          <div class="btn-group " dropdown>
            <button id="button-basic" dropdownToggle type="button"
              class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
              {{dashboardVar.cartSelectedVlaue.label}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let item of itemOptionsOrders ; let i = index">
                <a *ngIf="i !== 0" (click)="onChangeOrderBy(item,2)" class="c-pointer"
                  [ngClass]="dashboardVar.cartSelectedVlaue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                  {{ item.label }}
                </a>
              </li>
            </ul>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block p-0 ml-3 mr-4 " type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(cartAlerts)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
          <div class="position-absolute card-top-buttons mt-4 mr-3 p-0 pt-1 ">
            <button class="btn btn-header-light icon-button pl-0" (click)="loadCartridgeAlerts()">
              <i class="simple-icon-refresh p-0 m-0"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div [ngClass]="isFullScreen==true ? 'fullScreenClass' : 'dashboard-list-with-user' ">
          <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
            <div class="d-flex flex-row">
              <table class="table">
                <thead class="lead-th">
                  <tr>
                    <th [appSort]="dashboardVar.cartridgeAlertsArr" data-order="desc" data-name="customer.name"
                      scope="col" class="c-pointer">Customer
                      <i [ngClass]="arrowHead" aria-hidden="true" style="font-size: 10px !important;"></i>
                    </th>
                    <th scope="col">Generated On</th>
                    <th scope="col">Current Alert</th>
                  </tr>
                </thead>
                <tbody *ngIf="dashboardVar.cartridgeAlertsArr.length>0; else noDataCart" style="font-size: 10px">
                  <tr *ngFor="let cart of dashboardVar.cartridgeAlertsArr">
                    <td>
                      <p class="c-pointer">
                        <span (click)="openConsumerModal(cart)">{{cart.customer.name}} </span><i
                          class="fa fa-phone text-primary"></i><span class="text-primary">
                          {{cart.customer.phoneNumber}}</span>
                        <br />
                        <span class="text-muted text-small">
                          {{cart.customer.address}}, {{cart.customer.state}}, {{cart.customer.city}},
                          {{cart.customer.zip}}
                        </span>
                      </p>
                    </td>
                    <td>
                      <p style="font-size: 12px;">
                        {{this.global.utcDateToLocal(cart.generatedOn)}}
                      </p>
                    </td>
                    <td>
                      <img style="width: 20px; height: 20px" src="../../../../assets/images/alert_icon.png" alt="" />
                      <span> {{cart.definition}}</span>
                    </td>
                  </tr>
                </tbody>
                <ng-template #noDataCart>
                  <tbody>
                    <tr>
                      <td class="text-center" colspan="4">
                        <p class="card-title">
                          {{dashboardVar.loadingCartAlert}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </ng-template>
              </table>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
    <div class="card " #gaugeCharts>
      <div class="card-body pb-0">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">Performance</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <div class="btn-group" dropdown>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block mr-5 p-0" type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(gaugeCharts)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
        </div>
        <div class="position-absolute card-top-buttons pl-0 ml-0 ">
          <button class="btn btn-header-light icon-button" (click)="getst()">
            <i class="simple-icon-refresh"></i>
          </button>
        </div>
      </div>
      <div class="card-body mt-0 pt-0 performance">
        <div class="row mt-0">
          <div class="col-4 pl-0 ml-0 pt-0 ">
            <div id="chart-gauge">
            </div>
            <p class="text-primary  text-center font-weight-bold pr-3 meter-text">% installed in Water Alarms</p>
          </div>
          <div class="col-4  pl-0 ml-0 pt-0">
            <div id="Chart-gauge-speedo-meter"></div>

            <div id="chart-gauge2"></div>

            <p class="text-primary text-center font-weight-bold meter-text">Cartridge Health</p>
          </div>
          <div class="col-4  pl-0 ml-0 pt-0">
            <div id="chart-gauge3"></div>
            <p class="text-primary text-center font-weight-bold meter-text">eTrapp Health</p>
          </div>
        </div>
        <div class="row mt-4">
          <div
            [ngClass]="isFullScreen==true ? 'col-4 pl-5 font-weight-bold text-primary' : 'col-4 text-center pl-1 font-weight-bold text-primary' ">
          </div>
          <div
            [ngClass]="isFullScreen==true ? 'col-4  pl-5 font-weight-bold text-primary' : 'col-4 text-center pl-4 font-weight-bold text-primary' ">
          </div>
          <div
            [ngClass]="isFullScreen==true ? 'col-4  pl-5 font-weight-bold text-primary' : 'col-4 text-center pl-4 font-weight-bold text-primary' ">
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
    <div class="card " #gaugeCharts>
      <div class="card-body pb-0">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">Performance</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <div class="btn-group" dropdown>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block mr-5 p-0" type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(gaugeCharts)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
        </div>
        <div class="position-absolute card-top-buttons pl-0 ml-0 ">
          <button class="btn btn-header-light icon-button" (click)="getst()">
            <i class="simple-icon-refresh"></i>
          </button>
        </div>
      </div>
      <div class="card-body mt-0 pt-0 performance">
        <div class="row mt-0">
          <div class="col-5 pl-0 ml-0 pt-0 ">
            <div id="Chart-gauge-speedo-meter"></div>
          

            <p class="text-primary  text-center font-weight-bold pr-3 meter-text">Average Ticket Response</p>
          </div>
          <div class="col-3  pl-0 ml-0 pt-0 text-center">
            

            <!-- <div id="chart-gauge2"></div> -->
            <div style=" margin-top: 120px" >
              
              <app-rating [rating]="rating" [totalStars]="5"
              [showRating]="true"></app-rating>
            </div>

            <p class="text-primary font-weight-bold ">Customer Satisfaction</p>
          </div>
          <div class="col-4  pl-0 ml-0 pt-0">
          <div class="new-barchart">
            <div class=" " [ngClass]="mapbarchartdata.length!= 0?'d-block':'d-none'"> 
              <app-bar-chart #barchart [options]="barChartOptions1" [data]="barChartdata" [height]="'200px'"
              ></app-bar-chart>
              </div>
              <div class="card-chart-line" [ngClass]="mapbarchartdata.length== 0?'d-block':'d-none'">
                <p class="font-weight-bold position-relative m-nodata">No data to display</p>
              </div>
          </div>
            <p class="text-primary text-center font-weight-bold ">Cartridge Replacements</p>
            
            <!-- <span class=" text-center font-weight-bold meter-text" style="padding-left: 30px;">Orders to be filled:</span><span
            class="text-danger">23</span> -->
          </div>
        </div>
        <div class="row mt-4">
          <div
            [ngClass]="isFullScreen==true ? 'col-4 pl-5 font-weight-bold text-primary' : 'col-4 text-center pl-1 font-weight-bold text-primary' ">
          </div>
          <div
            [ngClass]="isFullScreen==true ? 'col-4  pl-5 font-weight-bold text-primary' : 'col-4 text-center pl-4 font-weight-bold text-primary' ">
          </div>
          <div
            [ngClass]="isFullScreen==true ? 'col-4  pl-5 font-weight-bold text-primary' : 'col-4 text-center pl-4 font-weight-bold text-primary' ">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
    <div class="card" #mapsDiv id="mapsDiv">
      <div class="card-body pb-0">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">Maps</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <div class="btn-group" dropdown>
            <div class="mt-1">
              <ul *ngIf="!isFullScreen" class="list-unstyled list-inline">
                <li class="list-inline-item">
                  <div class="custom-control custom-checkbox mb-2" 
                   tooltip="Pan flood, water level high (100%), AC Cutoff, Catrdige unlocked" placement="top"
                  tooltipClass="custom-tooltip">
                    <input [(ngModel)]="this.dashboardVar.alertLvlObj.isHigh"
                      (click)="onSelectLvl('High',this.dashboardVar.alertLvlObj.isHigh)" type="checkbox" id="High"
                      class="custom-control-input">
                    <label for="High" class="custom-control-label text-danger font-weight-bold">High</label>
                  </div>
                </li>
                <li class="list-inline-item">
                  <div class="custom-control custom-checkbox mb-2" id="medium"
                  tooltip="Cartridge life 7 days or less, Water level 0 or less." placement="top"
                 
     tooltipClass="custom-tooltip">
                    <input [(ngModel)]="this.dashboardVar.alertLvlObj.isMedium"
                      (click)="onSelectLvl('Medium',this.dashboardVar.alertLvlObj.isMedium)" type="checkbox" id="Medium"
                      class="custom-control-input">
                    <label for="Medium" class="custom-control-label text-warning font-weight-bold">Medium</label>
                  </div>
                </li>
                <li class="list-inline-item">
                  <div class="custom-control custom-checkbox mb-2" id="low"
                  tooltip="All others" placement="top" 
                  tooltipClass="custom-tooltip">
                    <input [(ngModel)]="this.dashboardVar.alertLvlObj.isLow"
                      (click)="onSelectLvl('Low',this.dashboardVar.alertLvlObj.isLow)" type="checkbox" id="Low"
                      class="custom-control-input">
                    <label for="Low" class="custom-control-label text-success font-weight-bold">Low</label>
                  </div>
                </li>
              </ul>
            </div>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block mr-5 mb-4 ml-3 p-0 mt-2" type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(mapsDiv,'map')">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
        </div>
        <div class="float-right float-none-xs mt-1">
          <ul *ngIf="isFullScreen" class="list-unstyled list-inline">
            <li class="list-inline-item">
              <div class="custom-control custom-checkbox mb-2">
                <input [(ngModel)]="this.dashboardVar.alertLvlObj.isHigh"
                  (click)="onSelectLvl('High',this.dashboardVar.alertLvlObj.isHigh)" type="checkbox" id="High"
                  class="custom-control-input">
                <label for="High" class="custom-control-label text-danger font-weight-bold">High</label>
              </div>
            </li>
            <li class="list-inline-item">
              <div class="custom-control custom-checkbox mb-2">
                <input [(ngModel)]="this.dashboardVar.alertLvlObj.isMedium"
                  (click)="onSelectLvl('Medium',this.dashboardVar.alertLvlObj.isMedium)" type="checkbox" id="Medium"
                  class="custom-control-input">
                <label for="Medium" class="custom-control-label text-warning font-weight-bold">Medium</label>
              </div>
            </li>
            <li class="list-inline-item">
              <div class="custom-control custom-checkbox mb-2">
                <input [(ngModel)]="this.dashboardVar.alertLvlObj.isLow"
                  (click)="onSelectLvl('Low',this.dashboardVar.alertLvlObj.isLow)" type="checkbox" id="Low"
                  class="custom-control-input">
                <label for="Low" class="custom-control-label text-success font-weight-bold">Low</label>
              </div>
            </li>
          </ul>
        </div>
        <div class="position-absolute card-top-buttons pl-0 mt-2 ">
          <button class="btn btn-header-light icon-button" (click)="loadMapdata()">
            <i class="simple-icon-refresh"></i>
          </button>
        </div>
      </div>
      <div class="card-body mt-0 pt-0">
        <div #gmap id="gmap" options="options" [ngClass]="isFullScreen==true ? 'mapFullScreen' : 'mapSmallScreen' ">
        </div>
        <ul class="list-unstyled list-inline mb-0 float-right">
          <li class="list-inline-item">
            <!-- <div class='box red'></div>
            <span class="ml-2">High</span> -->
          </li>
          <li class="list-inline-item">
            <!-- <div class='box yellow'></div>
            <span class="ml-2">Medium</span> -->
          </li>
          <li class="list-inline-item">
            <!-- <div class='box green'></div>
            <span class="ml-2">Low</span> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4  d-flex justify-content-between" style="padding: 0px 20px;" >
  <div class="metrics">
  <h1>Customer | Metrics</h1>
  </div>
  <div class="search-bar d-flex">
    <ng-select class="w-100 mr-2 rounded" [items]="customerData" (search)="onSearch($event)" bindLabel="fullName"
    placeholder="Search Customer" bindValue="id" (keydown)="onKeyDown($event)"
    (change)="selectCustomer($event)" (clear)="clearCustomerData()">
</ng-select>
<ng-select class="w-100 mr-2 rounded" [items]="etrapps" bindLabel="nickName" 
placeholder="Search eTrapp Unit" bindValue="macId"  [disabled]="true" (change)="selectMacID($event)"
(clear)="clearmacid()"
 >
</ng-select>
<!-- <span class="mr-1 font-weight-bold ">Show</span> -->
          <div dropdown style="position: relative;">
            <button id="button-basic" dropdownToggle type="button" [disabled]="macselected"
              class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
              {{dashboardVar.chartsSelectedValue.label}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"  *ngFor="let item of itemOptionsOrders; let i = index">
                <a *ngIf="i !== 0" (click)="onChangeOrderBy(item,3)" class="c-pointer"
                  [ngClass]="dashboardVar.chartsSelectedValue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                  {{ item.label }}
                </a>
              </li>
            </ul>
          </div>
  </div>
</div>

<div class="row mt-4 remover  d-flex justify-content-between" id="remover">
  
  <!-- <div  class="col-lg-6 col-xmd-4 col-md-4"> 
    <div class="card mt-1 ">
      <div class="card-body text-center">  
         <app-line-chart #Duallinechart [options]="duallinechartoptions" [data]="duallinechartdata" [height]="'200px'" ></app-line-chart>
      </div>
      
    </div>
  </div> -->
  <div class="col-lg-12 col-xmd-4 col-md-4"> 
    <div class="card mt-1 " #stockchart id="stockchart">
      <div class="card-body text-center">
        <div class="card-chart-line" [ngClass]="!stock?'d-block':'d-none'">
          <p class="font-weight-bold position-relative p-nodata">No data to display</p>
        </div>
        <!-- <div id="rh-chart"></div>  -->
        <div class="d-block" [chart]="stock" #stockChart ></div>  
      </div>
    </div>
  </div>
  </div>
<div class="row mt-4 remover  d-flex justify-content-center" id="remover">

  <div  class="col-lg-6 col-xmd-4 col-md-4"> 
    <div class="card mt-1 " #acrunning id="acrunning">
      <!-- <div class="card-body text-center">  
        <app-bar-chart #Ack [options]="amplitudeoptions" [data]="amplitudedata" [height]="'300px'"
            ></app-bar-chart>
      </div> -->
      <div class="card-body text-center">
        <div class="card-chart-line" [ngClass]="!stock?'d-block':'d-none'">
          <p class="font-weight-bold position-relative p-nodata">No data to display</p>
        </div>
        <!-- <div id="rh-chart"></div>  -->
        <div class="d-block" [chart]="stock1" #stockChart ></div>  
      </div>
      
    </div>
  </div>
  <div class="col-lg-6 col-xmd-2 col-md-2"> 
    <div class="card mt-1 "   id="airfilters">
      <div class="card-body text-center">
        <div class="card-chart-line" [ngClass]="!stock?'d-block':'d-none'">
          <p class="font-weight-bold position-relative p-nodata">No data to display</p>
        </div>
        <div [ngClass]="stock?'d-block':'d-none'">
        
          <div class="text-center">
            <h2 class="d-block card-title text-center ">Air Filters</h2>
          </div>
        
          <div [ngStyle]="{'height.px': airfilters.length!== 0? 340 : null}" class="">
            <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
              <table class="table"    >
                <thead class="lead-th">
                  <tr>
                    <th scope="col">Unit</th>
                    <th class="" scope="col ">Address</th>
                    <th scope="col" class="">Size</th>
                    <th scope="col" class="">Remaining life</th>

                  </tr>
                </thead>
                <tbody *ngIf="airfilters.length> 0; else nodatafilter" >
                  <tr *ngFor="let data of airfilters">
                    <td >{{data.deviceName}}</td>
                    <td>{{data.address}}</td>
                    <td>{{data.filterSize}}</td>
                    <td>{{data.remainingDays}} days</td>
                  </tr>
                </tbody>
                <ng-template #nodatafilter>
                  <tbody>
                    <tr>
                      <td class="text-center" colspan="4">
                        <p class="card-title">
                          No Filters Found
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </ng-template>
              </table>
            </perfect-scrollbar>
          </div>
        </div>
        
  </div>
  
</div>
</div>
  

</div>



<ng-template #noData>
  <div style="height:153px;">
    <p>No data to display</p>
  </div>
</ng-template>

<ng-template #addReseller>
  <div class="child-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Select Reseller</h4>
      <button *ngIf="this.dashboardVar.resellerId !== null" type="button" class="close pull-right" aria-label="Close"
        (click)="closeAddResellerModal()">
        <span aria-hidden=" true">&times;</span>
      </button>
    </div>

    <form [formGroup]="addResellerForm" novalidate (ngSubmit)="submitAddReseller(addResellerForm.value)">
      <div class="modal-body">
        <div class="row">
          <div class="form-grop col-12 ">
            <label> Reseller</label>
            <select class="form-control" formControlName="name">
              <option selected disabled hidden value="null">Select Reseller</option>
              <option value="" *ngIf="dashboardVar.resellers.length==0" disabled>No Reseller Found
              </option>
              <option *ngFor="let reseller of dashboardVar.resellers" [ngValue]="reseller.name">
                {{reseller.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" type="submit" [disabled]="!addResellerForm.valid">
          {{ addResellerForm.value.resellerId === 0 ? "Save" : "Update" }}
        </button>
        <button *ngIf="this.dashboardVar.resellerId !== null" class="btn btn-outline-danger" type="button"
          (click)="closeAddResellerModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #instructions>
  <div class="child-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">eTrapp Installation Guidelines</h4>
      <button *ngIf="this.dashboardVar.resellerId !== null" type="button" class="close pull-right" aria-label="Close"
        (click)="closeAddResellerModal()">
        <span aria-hidden=" true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-grop col-12 ">
          <p>
            What is Lorem Ipsum?
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button *ngIf="this.dashboardVar.resellerId !== null" class="btn btn-outline-danger" type="button"
        (click)="closeAddResellerModal()">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<app-consumer-modal #contentModal [isBorder]="true" (successEvent)="onSuccessFromParent()">
</app-consumer-modal>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDifference'
})
export class TimeDifferencePipe implements PipeTransform {

  transform(alertTime: Date): string {
    const currentTime = new Date();
    const timeDifference = Math.abs(currentTime.getTime() - new Date(alertTime).getTime());
    
    
    const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    if (days > 0) {
      return `${days} days ${hours} hours and ${minutes} mins`;
    } else {
      return `${hours} hours ${minutes} mins and ${seconds} secs`;
    }
  }

}
